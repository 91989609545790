<template>
<section class="dashboard-header">
        <div class="container-fluid">  
            <div class="card-header">
                <h2>{{msg}}</h2>
                <div>   
                </div>
            </div>

            <div id="incidencia" class="row bg-white has-shadow">
                <div class="col-md-9 offset-md-1">
                    <form class="form">
                        <vue-form-generator :schema="schema" :model="model">
                        </vue-form-generator>
                        <b-btn class="float-right mr-3 ml-3 mt-3" variant="secundary" @click="regresar">Regresar</b-btn>
                        <b-btn class="float-right mr-3 ml-3 mt-3" variant="primary" @click="grabar">Grabar</b-btn>                        
                    </form>
                </div>
            </div>
        </div>
    </section>
    
</template>

<script>
import axios from 'axios';

import Vue from 'vue'
import VueFormGenerator from 'vue-form-generator'
//import 'vue-form-generator/dist/vfg.css' 
Vue.use(VueFormGenerator)

export default {
     
    data() {
        return {
            msg: 'Editar Tienda',
            model: this.$route.params.model,
            schema: this.$route.params.fields,
            
            
        }        
    },
    methods: {
        grabar(){            

            console.log(JSON.stringify(this.model))


            axios.defaults.headers.common['Authorization'] = 'Bearer ' + this.$cookies.get('token');
            axios.post(process.env.VUE_APP_ROOT_API + "api/secure/tiendas", this.model)
            .then((response) => {                
                this.$router.replace({ path: '/portal/tiendas' });                                                                 
            }, (error) => {
                //console.log("Error: " + JSON.stringify( error.data) );
            })
        },
        regresar(){
            this.$router.replace({ path: '/portal/tiendas' });
            //.go(-2);
        }        
    }

}
</script>

<style>
    small.text-info{color: #7000ff}
    div#incidencia{margin-top: 10px; padding-bottom: 20px;}
    section.dashboard-header{padding: 10px 0;}  
    div.autocomplete{width: 400px}

    
</style>
